<template>
  <div class="campagin_form">
    <!-- 复制广告系列 -->
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      class="myCampagin"
      label-suffix="："
      label-width="120px"
    >
      <el-form-item label="源广告账户">
        <span>{{ currentNodeCampagin.name }}</span>
      </el-form-item>
      <el-form-item
        label="目标广告账户"
        prop="toAccountId"
      >
        <el-select
          v-model.trim="ruleForm.toAccountId"
          filterable
          :remote="true"
          reserve-keyword
          placeholder="请输入目标广告账户"
          :remote-method="f_remoteMethod"
          value-key="id"
          @change="changeToAccountId"
        >
          <el-option
            v-for="item in accountData"
            :key="item.id"
            :label="item.adaccount.name + '#' + item.adaccount.id"
            :value="item"
          >
            <div class="self_account flex">
              <div>
                <p>
                  <span>账户：</span><span>{{ item.adaccount.name }}#{{ item.adaccount.id }}</span>
                </p>
                <p>
                  <span>凭证：</span><span>{{ item.credential.displayName }}#{{ item.credential.id }}</span>
                </p>
              </div>
              <p>
                <span>经理ID：</span><span>{{ item.rootMccid }}</span>
              </p>
            </div>
          </el-option>
          <div
            slot="empty"
            class="empty"
            v-loading="loading"
          >
            <i class="iconfont icon-wushuju"></i>
            <p>暂无数据</p>
          </div>
        </el-select>
        <div v-if="ruleForm.toAccountId">
          <span v-if="!setupInfo.isSetup">
            <span><i class="el-icon-warning color-warning mr-5"></i>广告账户未进行API配置</span>
            <el-button
              type="text"
              @click="toggleSetupAccountVisible(true)"
              >去配置</el-button
            >
          </span>
          <span v-else>
            <span v-if="setupInfo.ctsCtStatus == 'CONVERSION_TRACKING_MANAGED_BY_SELF'">
              <i class="el-icon-success color-success mr-5"></i>广告账户已完成配置
            </span>
            <span v-else>
              <el-tooltip
                content="转化目标和操作由经理账号进行管理的， API回传转化不生效。请前往经理账户下，在设置-->子账户设置中将Google Ads转化账户设置为客户"
              >
                <span><i class="el-icon-warning color-warning mr-5"></i>广告账户已完成配置但未生效</span>
              </el-tooltip>
            </span>
            <p >
              <i class="el-icon-warning color-warning mr-5"></i>广告账户配置投放类型与投放链接类型不一致
            </p>
            <el-button
              class="ml-10"
              type="text"
              @click="toggleSetupAccountVisible(true)"
              >重新配置</el-button
            >
          </span>
        </div>
      </el-form-item>
      <el-form-item
        label="目标网站"
        prop="toSiteId"
      >
        <el-select
          v-model.trim="ruleForm.toSiteId"
          filterable
          :remote="true"
          reserve-keyword
          placeholder="请输入目标网站"
          :remote-method="f_remoteMethodSite"
          value-key="id"
          @change="selectSite"
        >
          <el-option
            v-for="item in sitesData"
            :key="item.id"
            :label="item.domain + '#' + item.id + '(' + item.saleName + ')'"
            :value="item"
          >
            <span>{{ item.domain }}#{{ item.id }}({{ item.saleName }})</span>
          </el-option>
          <div
            slot="empty"
            class="empty"
            v-loading="loading"
          >
            <i class="iconfont icon-wushuju"></i>
            <p>暂无数据</p>
          </div>
        </el-select>
      </el-form-item>
      <el-form-item
        label="站点分类"
        prop="value"
      >
        <el-select
          v-model="ruleForm.toSiteCateId"
          placeholder="请选择"
          filterable
          clearable
        >
          <el-option
            v-for="item in siteCatList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="是否克隆商品"
        prop="cloneSale"
      >
        <el-checkbox v-model="ruleForm.cloneSale"></el-checkbox>
      </el-form-item>
      <!-- <el-form-item label="替换商家名称" prop="checked">
                <el-checkbox v-model="ruleForm.checked"></el-checkbox>
            </el-form-item> -->
      <!-- <el-form-item label="复制后状态" prop="value">
                <el-select v-model="ruleForm.value" placeholder="请选择">
                    <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item> -->
    </el-form>
    <el-row class="sumit">
      <el-button
        type="primary"
        @click="f_onSubmit"
        >确 定</el-button
      >
    </el-row>
    <!-- 批量复制修改 -->
    <my-dialog :myDialogObj="visibleChooseType">
      <bulk
        ref="draftBox"
        @close="f_close"
        editType="copy"
        :accountId="accountId"
      ></bulk>
    </my-dialog>
    <!-- 　广告账户API配置 -->
    <setupAccount
      :visible="setupAccountVisible"
      @toggleVisible="toggleSetupAccountVisible"
      :account_ids="ruleForm.toAccountId ? [ruleForm.toAccountId.adaccount.id] : []"
      :isOne="true"
      @success="checkAccountSetup"
    ></setupAccount>
  </div>
</template>

<script>
import MyDialog from '@/views/google/createGgAd/common/myDialog/index.vue';
import bulk from '@/views/google/bulkCopy/components/bulk/index.vue';
import { searchSites } from '@/api/google/adManagement.js';
import { searchAccount } from '@/api/google/account.js';
import { copyAd, siteCategorys, checkAccountSetup } from '@/api/google/ceateAdGg.js';
import setupAccount from '../../accountManagement/components/setupAccount.vue';
export default {
  components: { MyDialog, bulk, setupAccount },
  props: {
    accountId: {
      type: [String, Array],
      default: '',
    },
    currentNodeCampagin: {
      type: Object,
      default: () => {},
    },
    level: {
      type: String,
      default: 'campaign',
    },
    list: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      visibleChooseType: {
        title: '批量复制',
        dialogVisible: false,
        width: '80%',
        top: '10vh',
      },
      count: '', //倒计时2s后跳转
      timer: null, //计时器
      loading: false,
      ruleForm: {},
      rules: {
        toAccountId: [{ required: true, message: '请选择目标广告账户', trigger: ['change', 'blur'] }],
      },
      accountData: [], //账户数据
      sitesData: [], //可用域名
      siteCatList: [],
      setupInfo: {}, // 设置账户转化
      setupAccountVisible: false,
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  beforeDestroy() {},
  methods: {
    // 关闭
    f_close() {
      this.visibleChooseType.dialogVisible = false;
      this.$emit('closeCreate');
    },
    // 确定
    f_onSubmit() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (!valid) return false;
        let params = {
          fromAccountId: this.accountId[0],
          level: this.level,
          fromCampaign: this.list[0].resource_name,
          toAccountId: this.ruleForm.toAccountId.adaccount.id,
          toSiteId: (this.ruleForm.toSiteId && this.ruleForm.toSiteId.id) || '',
          cloneSale: this.ruleForm.cloneSale,
          toSiteCateId: this.ruleForm.toSiteCateId,
        };

        if (!this.setupInfo.isSetup) {
          this.$confirm('广告账户未进行API配置，是否配置后在继续?', '账户配置验证', {
            confirmButtonText: '配置',
            cancelButtonText: '继续',
            type: 'warning',
          })
            .then(() => {
              this.toggleSetupAccountVisible(true);
            })
            .catch(async () => {
              this.copyAd(params);
            });
        } else {
          this.copyAd(params);
        }
      });
    },
    async copyAd(params) {
      this.$showLoading();
      let res = await copyAd(params);
      this.$hideLoading();
      if (res.code != 0) return;
      const timejump = 1;
      let loading = null;
      if (!this.timer) {
        this.count = timejump;
        loading = this.$loading({
          lock: true,
          text: `正在为您跳转，请稍等...`,
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.4)',
        });
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= timejump) {
            this.count--;
          } else {
            clearInterval(this.timer);
            this.timer = null; //跳转的页面写在此处
            loading.close();
            this.visibleChooseType.dialogVisible = true;
            this.$nextTick(() => {
              this.$refs.draftBox.f_handleBulkData(res.data);
            });
          }
        }, 500);
      }
    },

    // 选择的账户
    f_remoteMethod(query) {
      this.accountData = [];
      this.loading = true;
      searchAccount({ accountName: query, page: 1, pageSize: 20 }).then((res) => {
        this.loading = false;
        if (res.code == 0 && res.data.content.length) {
          this.accountData = res.data.content;
        } else {
          this.accountData = [];
        }
      });
    },
    // 可用的域名
    f_remoteMethodSite(query) {
      this.sitesData = [];
      this.loading = true;
      searchSites({ keyword: query, gkshop: true }).then((res) => {
        // console.log(res);
        this.loading = false;
        if (res.code == 0 && res.data.length) {
          this.sitesData = res.data;
        } else {
          this.sitesData = [];
        }
      });
    },
    selectSite(v) {
      console.log(v);
      this.$set(this.ruleForm, 'toSiteCateId', null);
      // this.ruleForm.toSiteCateId = '';
      siteCategorys({ siteId: v.id }).then((res) => {
        this.siteCatList = [{ name: '隐藏分类', id: null }].concat(res.data || []);
      });
    },
    changeToAccountId(val) {
      this.checkAccountSetup(val.adaccount.id);
    },
    checkAccountSetup(accountId) {
      checkAccountSetup({ accountId: Array.isArray(accountId) ? accountId.join(',') : accountId }).then((res) => {
        console.log(res);
        this.setupInfo = res.data;
      });
    },
    toggleSetupAccountVisible(bool) {
      this.setupAccountVisible = bool;
    },
  },
};
</script>

<style lang="scss" scoped>
.el-select-dropdown__item {
  height: auto;
  line-height: 30px;
}
.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.self_account {
  padding: 3px 0;
}
.campagin_form {
  .myCampagin {
    ::v-deep.el-form-item {
      width: 100%;
      .el-form-item__label {
        width: 108px;
        text-align: left;
        position: relative;
        &::before {
          position: absolute;
          top: 0;
          left: -10px;
        }
      }
      .el-form-item__content {
        width: calc(100% - 110px);
        .el-input,
        .el-select {
          width: 100%;
        }
      }
    }
  }
}

.sumit {
  text-align: right;
  margin-bottom: 10px;
  margin-right: 10px;
}
</style>

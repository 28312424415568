<template>
    <div class="adsets_content">
        <div class="edit_adgroup_google">
            <!-- 4 受众特征-->
            <div class="title_part" id="id7">受众特征</div>
            <audience v-model="ruleForm.adgroup_criterias" @input="f_inputAdgroup"></audience>
            <!-- 5 受众群体-->
            <div class="title_part tit_heigth_10" id="id8">受众群体</div>
            <div style="margin: 10px  20px;">
                <interest v-model="ruleForm.adgroup_criterias" @input="f_inputAdGroupBrowse"></interest>
            </div>
        </div>
        <el-row class="edit_adsets_footer">
            <el-button @click="f_cancel">取 消</el-button>
            <el-button type="primary" @click="f_sure">确 定</el-button>
        </el-row>
    </div>
</template>

<script>
import {updateAdgroupAudiences} from '@/api/google/ceateAdGg.js'
import audience from '@/views/google/createGgAd/components/audience/index.vue'
import interest from '@/views/google/createGgAd/components/interest/index.vue'
export default {
    components:{audience,interest},
    props:{
        value:{
            type:Object,
            default:()=>{}
        },
        originData:{
            type:Array,
            default:()=>[]
        },
        accountId: {
            type: [String, Array],
            default: "",
        },
    },
    data(){
        return {
          ruleForm:this.value,
        }
    },
    computed:{},
    watch:{},
    created(){},
    mounted(){},
    beforeDestroy(){},
    methods:{
        //   受众特征
        f_inputAdgroup(data){
            // 合并受众浏览和受众特征的数据
            this.ruleForm.adgroup_criterias = [...data]
        },
        // 受众浏览
        f_inputAdGroupBrowse(data){
            // 合并受众浏览和受众特征的数据
            this.ruleForm.adgroup_criterias = [...data]
        },
        async f_sure(){
            console.log(this.originData,"提交了----")
            let obj = {...this.ruleForm,'adgroup_criterias':[...this.originData]}
            this.$showLoading()
            let res = await updateAdgroupAudiences({accountId:this.accountId[0],sourceAd:obj,changedAd:this.ruleForm})
            this.$hideLoading()
            if(res.code == 0){
                this.$message.success("修改成功！")
                this.$emit("closeCreate")
            }
        },
        f_cancel(){
            this.$emit("closeCreate")
        },
    },
}
</script>

<style lang="scss" scoped>
.adsets_content{
    position: relative;
    .edit_adsets_footer{
        position: absolute;
        bottom: 0;
        right: 20px;
        background-color: #fff;
        height: 40px;
        width: 100%;
        text-align: right;
    }
}
.edit_adgroup_google{
    padding: 10px 0;
    max-height: 650px;
    overflow: hidden;
    overflow-y: auto;
    position: relative;
    padding-bottom: 40px;
    .title_part{
        margin-left: 25px;
    }
    .google_tit{
        padding: 0 20px;
    }
}
</style>
<template>
  <div class="setupAccount">
    <el-dialog
      title="转化配置"
      width="600px"
      :visible="visible"
      @close="toggleVisible(false)"
      append-to-body
    >
      <el-form
        :model="formData"
        label-width="100px"
        label-suffix="："
      >
      <el-form-item label="链接来源">
          <el-radio-group v-model="formData.utmSourceConfig">
            <el-radio label="GMALL3">商城3.0</el-radio>
            <el-radio label="SHOPLINE">shopline</el-radio>
            <el-radio label="SHOPIFY">shopify</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="主要目标">
          <el-radio-group v-model="formData.setData">
            <el-radio label="purchase_primary">购买</el-radio>
            <el-radio label="checkout_primary">开始结账</el-radio>
            <el-radio :label="false">无</el-radio>
          </el-radio-group>
          <div class="tips">
            <span v-show="!formData.setData">需要在账户中配置API转化之外的其他跟踪事件</span>
          </div>
        </el-form-item>
      </el-form>
      <div
        solt="footer"
        class="dp-flex justify-end"
      >
        <el-button
          @click="toggleVisible(false)"
          :loading="loading"
          >取消</el-button
        >
        <el-button
          type="primary"
          @click="sure"
          :loading="loading"
          >确定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { setupAccount } from '@/api/google/account';
export default {
  props: {
    visible: Boolean,
    account_ids: Array,
    isOne: Boolean,
    settinginfo:{
      type: Object,
      default:()=>{}
    }
  },
  data() {
    return {
      loading: false,
      formData: {
        setData: 'purchase_primary',
        utmSourceConfig:'GMALL3',
        // purchase_primary: true, // 加购配置
        // checkout_primary: false, // 结账设置
      },
    };
  },
  methods: {
    toggleVisible(bool) {
      if (this.loading) return;
      this.$emit('toggleVisible', bool);
    },
    sure() {
      let params = {
        purchase_primary: false,
        checkout_primary: false,
      };
      if (this.formData.setData) {
        params[this.formData.setData] = true;
      }
      if (this.isOne) {
        params.account_id = this.account_ids.join('');
      } else {
        params.account_ids = this.account_ids;
      }
      params.utmSourceConfig = this.formData.utmSourceConfig;
      this.loading = true;
      setupAccount(params)
        .finally(() => {
          this.loading = false;
        })
        .then((res) => {
          if (res.code == 0) {
            this.$message.success(this.isOne ? '设置成功' : '一键配置后台执行中，执行完成后将通过钉钉消息提醒');
            setTimeout(() => {
              this.$emit('success', this.account_ids);
              this.toggleVisible(false);
            }, 1000);
          }
        });
    },
  },
  watch: {
    visible() {
      this.formData = {
        setData: 'purchase_primary',
        utmSourceConfig:this.settinginfo.utmSourceConfig
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.setupAccount {
  ::v-deep .el-dialog {
    border-radius: 10px;
  }
  .tips {
    font-size: 12px;
    color: #999;
    height: 20px;
    line-height: 20px;
  }
}
</style>

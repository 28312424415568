<template>
<div class="edit_ads_content">
    <div class="edit_ad_google">
        <ads v-model="ruleForm" :accountId="accountId"  @input="f_inputAds" :isPass="isPass"></ads>
    </div>
    <el-row class="edit_ads_footer">
        <el-button @click="f_cancel">取 消</el-button>
        <el-button type="primary" @click="f_sure">确 定</el-button>
    </el-row>
</div>
</template>

<script>
import { cloneDeep } from 'lodash'
import {mapState} from 'vuex'
import {updateAds} from '@/api/google/ceateAdGg.js'
import ads from '@/views/google/bulkCopy/components/ads/index.vue'
export default {
    components:{ads},
    props:{
        value:{
            type:Object,
            default:()=>{}
        },
        originData:{
            type:Array,
            default:()=>[]
        },
        accountId: {
            type: [String, Array],
            default: "",
        },
    },
    data(){
        return {
          ruleForm:this.value,
          isPass:false,
        }
    },
    computed:{
        ...mapState({
            googleValid: state => state.initData.googleValid,
            googleValidName: state => state.initData.googleValidName,
        }),
    },
    watch:{},
    created(){},
    mounted(){},
    beforeDestroy(){},
    methods:{
        f_inputAds(data){
            this.ruleForm = cloneDeep(data)
            console.log(this.ruleForm,"同步过来的广告数据")
        },
        f_cancel(){
            this.$emit("closeCreate")
        },
        f_sure(){
            this.isPass = !this.isPass
            setTimeout(async () => {
                console.log(this.googleValid,"是否通过了",this.ruleForm,this.googleValidName)
                if(!this.googleValid)  return false;
                if(!this.googleValidName)  return false;
                console.log("通过了-----")
                this.$showLoading()
                let res = await updateAds({sourceAd:this.originData[0],changedAd:this.ruleForm,accountId:this.accountId[0]})
                this.$hideLoading()
                if(res.code == 0){
                    this.$message.success("广告修改成功！")
                    this.$emit("closeCreate")
                }
            }, 500);
        },
    },
}
</script>

<style lang="scss">
.edit_ads_content{
    position: relative;
    .edit_ads_footer{
        position: absolute;
        bottom: 0;
        right: 20px;
        background-color: #fff;
        height: 40px;
        width: 100%;
        text-align: right;
    }
    .create_gg_ad{
        max-height: 650px;
        overflow: hidden;
        overflow-y: auto;
        padding-bottom: 40px;
        .rt_ad_content{
            border-left: 1px solid #fff;
        }
    }
}
</style>